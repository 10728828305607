import { $, $$ } from './../helpers/qsa'

$$('.country-auth__enter').forEach(el => {

    el.addEventListener('click', e => {
        e.preventDefault()

        // Hide country select panel
        const countrySelect = document.getElementById('country-select-panel')
        countrySelect.setAttribute('hidden', true)

        // Hide self
        el.setAttribute('hidden', true)

        // Show choices
        $('.country-auth__choice').removeAttribute('hidden')
    })

})

// Update local URL for 'Continue link'
$$('.country-selector').forEach(el => {
    const link = $('a', document.getElementById('modal-local'))

    $('select', el).addEventListener('change', e => link.href = e.target.value)
})
