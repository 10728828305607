import { $$ } from './../helpers/qsa'
import MicroModal from 'micromodal'

MicroModal.init({
    onShow: modal => {
        document.documentElement.style.overflowY = 'hidden'
    },
    onClose: modal => {
        document.documentElement.style.overflowY = 'auto'

        $$('audio', modal).forEach(audio => {
            audio.pause()
            audio.currentTime = 0
        })
    }
})
