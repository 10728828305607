import { $, $$ } from './../helpers/qsa'
import MicroModal from 'micromodal'

const continueLink = $('.js-external-link-continue')

const handleClick = e => {
    // Return early if link should be ignored
    if (e.currentTarget.hasAttribute('data-external-ignore')) {
        return
    }

    // Handle modal link clicks
    if (e.currentTarget.classList.contains('js-external-link-continue')) {
        MicroModal.close('modal-external-link')
        return
    }

    // Get href
    const { hostname, href } = e.currentTarget

    // Check hostname againt current page
    if (hostname !== window.location.hostname) {
        e.preventDefault()

        // Update modal link and show modal
        continueLink.href = href
        MicroModal.show('modal-external-link')
    }

}

$$('a').forEach(el => el.addEventListener('click', handleClick))
